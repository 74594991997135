import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Input from "@mui/material/Input";

const Sidebar = ({ openNav }) => {
  const [selectedTab, setSelectedTab] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const { pathname } = useLocation();

  useEffect(() => {
    setSelectedTab(pathname);
  }, [pathname]);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const renderNavLink = (to, label, icon) => (
    <Link
      to={to}
      className={`nav-link ${selectedTab === to ? "selected" : ""}`}
      onClick={() => setSelectedTab(to)}
    >
      {icon && <i className={`${icon}`} />}
      <span>{label}</span>
    </Link>
  );

  const renderSubNav = (title, links, icon) => {
    if (title === "Whatsapp Campaign") {
      return (
        <li className="nav-item" key={title}>
          <a
            className="nav-link collapsed"
            data-bs-toggle="collapse"
            href={`#Whatapp-nav`}
          >
            {icon && <i className={`${icon}`} />}
            <span>{title}</span>
            <i className="bi bi-chevron-down ms-auto" />
          </a>
          <ul
            id={`Whatapp-nav`}
            className="nav-content collapse"
            data-bs-parent="#sidebar-nav"
          >
            {links.map((link, index) => (
              <li key={index}>
                {renderNavLink(link.to, link.label, link.icon)}
              </li>
            ))}
          </ul>
        </li>
      );
    } else if (title === "SMS Marketing") {
      return (
        <li className="nav-item" key={title}>
          <a
            className="nav-link collapsed"
            data-bs-toggle="collapse"
            href={`#activities-nav`}
          >
            {icon && <i className={`${icon}`} />}
            <span>{title}</span>
            <i className="bi bi-chevron-down ms-auto" />
          </a>
          <ul
            id={`activities-nav`}
            className="nav-content collapse"
            data-bs-parent="#sidebar-nav"
          >
            {links.map((link, index) => (
              <li key={index}>
                {renderNavLink(link.to, link.label, link.icon)}
              </li>
            ))}
          </ul>
        </li>
      );
    } else if (title === "SMS Manage") {
      return (
        <li className="nav-item" key={title}>
          <a
            className="nav-link collapsed"
            data-bs-toggle="collapse"
            href={`#sms-nav`}
          >
            {icon && <i className={`${icon}`} />}
            <span>{title}</span>
            <i className="bi bi-chevron-down ms-auto" />
          </a>
          <ul
            id={`sms-nav`}
            className="nav-content collapse"
            data-bs-parent="#sidebar-nav"
          >
            {links.map((link, index) => (
              <li key={index}>
                {renderNavLink(link.to, link.label, link.icon)}
              </li>
            ))}
          </ul>
        </li>
      );
    }

    return (
      <li className="nav-item" key={title}>
        <a
          className="nav-link collapsed"
          data-bs-toggle="collapse"
          href={`#${title.toLowerCase()}-nav`}
        >
          {icon && <i className={`${icon}`} />}
          <span>{title}</span>
          <i className="bi bi-chevron-down ms-auto" />
        </a>
        <ul
          id={`${title.toLowerCase()}-nav`}
          className="nav-content collapse"
          data-bs-parent="#sidebar-nav"
        >
          {links.map((link, index) => (
            <li key={index}>{renderNavLink(link.to, link.label, link.icon)}</li>
          ))}
        </ul>
      </li>
    );
  };

  const navigationItems = [
    { to: "/dashboard", label: "Dashboard", icon: "bi-grid" },
    {
      title: "Reporting",
      links: [
        { to: "/report-list", label: "Report", icon: "bi-circle" },
        { to: "/live-calls", label: "Live Calls", icon: "bi-circle" },
      ],
      icon: "bi-menu-button-wide",
    },
    {
      title: "Numbers",
      links: [
        {
          to: "/purchase-number-list",
          label: "Purchase Number List",
          icon: "bi-circle",
        },
        {
          to: "/block-number",
          label: "Manage Block Numbers",
          icon: "bi-circle",
        },
      ],
      icon: "bi-journal-text",
    },
    // {
    //   title: "IVR",
    //   links: [
    //     {
    //       to: "/call-greetings",
    //       label: "Call Greetings",
    //       icon: "bi-circle",
    //     },
    //     {
    //       to: "/ivr-service",
    //       label: "IVR Service",
    //       icon: "bi-circle",
    //     },
    //   ],
    //   icon: "bi-journal-text",
    // },

    { to: "/publishers", label: "Publishers", icon: "bi-person" },
    {
      title: "Targets",
      links: [
        { to: "/manage-buyers", label: "Manage Buyers", icon: "bi-circle" },
        { to: "/create-targets", label: "Create Targets", icon: "bi-circle" },
        { to: "/manage-targets", label: "Manage Targets", icon: "bi-circle" },
        // { to: "/manage-groups", label: "Manage Groups", icon: "bi-circle" },
      ],
      icon: "bi-bar-chart",
    },
    {
      title: "Campaigns",
      links: [
        {
          to: "/create-campaigns",
          label: "Create Campaigns",
          icon: "bi-circle",
        },
        {
          to: "/manage-campaigns",
          label: "Manage Campaigns",
          icon: "bi-circle",
        },
      ],
      icon: "bi-layout-text-window-reverse",
    },
    // {
    //   title: "SMS Marketing",
    //   category: "activities",
    //   links: [
    //     { to: "/AddContact", label: "Contact Management", icon: "bi-circle" },
    //     { to: "/sms-templates", label: "SMS Templates", icon: "bi-circle" },
    //     { to: "/compose", label: "Compose SMS", icon: "bi-circle" },
    //     { to: "/bulk-sms", label: "Bulk SMS", icon: "bi-circle" },
    //     { to: "/bulksms-range", label: "Bulk SMS Range", icon: "bi-circle" },
    //     { to: "/dynaminc-sms", label: "Dynaminc SMS", icon: "bi-circle" },
    //     {
    //       to: "/bulk-scheduling",
    //       label: "Bulk Scheduling SMS",
    //       icon: "bi-circle",
    //     },
    //     { to: "/db-camaign", label: "DB Campaign", icon: "bi-circle" },
    //     { to: "/dlr-report", label: "DLR Report", icon: "bi-circle" },
    //     { to: "/refund-report", label: "Refund Report", icon: "bi-circle" },
    //   ],
    //   icon: "fa-solid fa-comment-sms",
    // },
    // {
    //   title: "SMS Manage",
    //   category: "sms",
    //   links: [
    //     { to: "/manage-senders", label: "Manage Senders", icon: "bi-circle" },
    //     {
    //       to: "/manage-templates",
    //       label: "Manage Templates",
    //       icon: "bi-circle",
    //     },
    //     {
    //       to: "/pricing-coverage",
    //       label: "Pricing & Coverage",
    //       icon: "bi-circle",
    //     },
    //   ],
    //   icon: "fa-solid fa-comment-sms",
    // },
    // {
    //   title: "Whatsapp",
    //   links: [
    //     { to: "/device", label: "Device", icon: "bi-circle" },
    //     { to: "/auto-reply", label: "AutoReply", icon: "bi-circle" },
    //     {
    //       to: "/welcome-template",
    //       label: "Welcome Templates",
    //       icon: "bi-circle",
    //     },
    //     { to: "/templates", label: "Templates", icon: "bi-circle" },
    //     { to: "/send-messages", label: "Send Messages", icon: "bi-circle" },
    //     {
    //       to: "/send-messages-report",
    //       label: "Send Messages Report",
    //       icon: "bi-circle",
    //     },
    //     {
    //       to: "/received-messages-report",
    //       label: "Received Messages Report",
    //       icon: "bi-circle",
    //     },
    //   ],
    //   icon: "fa-brands fa-whatsapp",
    // },
    // {
    //   title: "Whatsapp Campaign",
    //   category: "Whatapp",
    //   links: [
    //     { to: "/db-campaignapp", label: "DB Campaign", icon: "bi-circle" },
    //     {
    //       to: "/internation-campaign",
    //       label: "Internation Campaign",
    //       icon: "bi-circle",
    //     },
    //     { to: "/campaign-report", label: "Campaign Report", icon: "bi-circle" },
    //   ],
    //   icon: "fa-brands fa-square-whatsapp",
    // },
    {
      title: "Phone",
      links: [
        { to: "/calls", label: "Calls", icon: "bi-circle" },
        { to: "/dial-pad", label: "Dial Pad", icon: "bi-circle" },
        { to: "/manage-users", label: "Manage Users", icon: "bi-circle" },
        { to: "/ring-group", label: "Ring Group", icon: "bi-circle" },
        { to: "/call-history", label: "Call History", icon: "bi-circle" },
      ],
      icon: "fa-brands fa-square-whatsapp",
    },
    {
      title: "Settings",
      links: [
        // { to: "/manage-user", label: "Manage Users", icon: "bi-circle" },
        { to: "/profile", label: "Profile", icon: "bi-circle" },
      ],
      icon: "fa-solid fa-gear",
    },
    { to: "/Support", label: "Support", icon: "fa-solid fa-circle-info" },
  ];

  // Filtered navigation items based on search query
  const filteredNavItems = navigationItems
    .map((item) => {
      if (item.title) {
        const filteredSubLinks = item.links.filter((link) =>
          link.label.toLowerCase().includes(searchQuery.toLowerCase())
        );
        if (filteredSubLinks.length > 0) {
          return {
            ...item,
            links: filteredSubLinks,
          };
        } else {
          return null; // Hide the main item if none of its sub-items match
        }
      } else if (item.label.toLowerCase().includes(searchQuery.toLowerCase())) {
        return item;
      } else {
        return null; // Hide individual items that don't match
      }
    })
    .filter(Boolean); // Remove null items

  const ariaLabel = { "aria-label": "description" };

  return (
    <>
      <aside id="sidebar" className={`sidebar ${openNav ? "active" : ""}`}>
        <Input
          fullWidth
          placeholder="Search..."
          inputProps={ariaLabel}
          value={searchQuery}
          onChange={handleSearchChange}
          style={{ marginBottom: "15px" }}
        />

        <ul className="sidebar-nav" id="sidebar-nav">
          {filteredNavItems.map((item, index) => (
            <React.Fragment key={index}>
              {item.title ? (
                renderSubNav(item.title, item.links, item.icon)
              ) : (
                <li key={item.to}>
                  {renderNavLink(item.to, item.label, item.icon)}
                </li>
              )}
            </React.Fragment>
          ))}
        </ul>
      </aside>
    </>
  );
};

export default Sidebar;
