import React, { useState, useEffect } from "react";
import { FaCalendarAlt } from "react-icons/fa";
// import DatePicker from "react-datepicker";
import {} from "@ant-design/charts";
import { campaigncolumns, campaigndata } from "./ReportListData";
import { publishercolumns, publisherdata } from "./ReportListData";
import { buyercolumns, buyerdata } from "./ReportListData";
import { targetcolumns, targetdata } from "./ReportListData";
// import { callcolumns, calldata } from "./ReportListData";
import Form from "react-bootstrap/Form";
import moment from "moment-timezone"; // Import the library
import DataTable from "react-data-table-component";

import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { DatePicker, Space } from "antd";
import { Country, City } from "country-state-city";
// const { RangePicker } = DatePicker;
import { useSelector } from "react-redux";
import { CallGETAPI, CallPOSTAPI, FILE_BASE_RECORDING, } from "../helper/Constants";
import { GetTimeZoneById } from "../helper/utils";
import { subMonths, startOfToday, format } from 'date-fns';


const ReportList = () => {
  const { RangePicker } = DatePicker;
 
  // const [countries, setCountries] = useState([]);
  // const [selectedCountry, setSelectedCountry] = useState("");
  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState("");
  const [callData, setCallData] = useState([]);
  const sip = useSelector((state) => state.wallet.extension_number);
  const user_id = useSelector((state) => state.wallet.userId);
  const [chartData, setChartData] = useState(null);

  const [filter, setFilter] = useState({
    did: '',
    uniqueid: '',
    call_from: '',
    call_to: '',
    user_id: ''
  });
 
  // const [startDate, setStartDate] = useState(null);
  // const [startDate, setStartDate] = useState(moment().subtract(1, "month")); // moment object
  // const [endDate, setEndDate] = useState(moment()); // moment object

  // const [startDate, setStartDate] = useState(dayjs().subtract(1, "month")); // dayjs object
  // const [endDate, setEndDate] = useState(dayjs());


 const [startDate, setStartDate] = useState(subMonths(new Date(), 1));
// const [startDate, setStartDate] = useState(subMonths());
  const [endDate, setEndDate] = useState(startOfToday());


  useEffect(() => {
    if (startDate && endDate) {
      fetchCallDetail();
      
    }
  }, [startDate, endDate, user_id]);

  const handleDateRangeChange = async (dates) => {
    if (dates && dates.length === 2) {
      const [start, end] = dates;
      setStartDate(start);
      setEndDate(end);  
    }
  };

  const fetchCallDetail = async () => {
    try {
      const response = await CallPOSTAPI(`api/calls/date-range`, {
        start_time: startDate,
        end_time: endDate,
        user_id: user_id, 
      });
  
      if (response.status) {
        const result = response.data.data;
  
        // Define labels and datasets even if result is empty
        const labels = result.length ? result.map(item => item.date) : getDefaultLabels();
        const noAnswered = result.length ? result.map(item => item.NOANSWEREDCount) : labels.map(() => 0);
        const incoming = result.length ? result.map(item => item.incomingCount) : labels.map(() => 0);
        const answered = result.length ? result.map(item => item.ANSWERCount) : labels.map(() => 0);
        const canceled = result.length ? result.map(item => item.CANCELCount) : labels.map(() => 0);
  
        // Set chart data with either the fetched data or zeroed out data
        setChartData({
          labels,
          datasets: [
            {
              label: "No Answered",
              data: noAnswered,
              borderColor: "#58BF6A",
              backgroundColor: "rgba(88, 191, 106, 0.2)",
              tension: 0.4,
              pointRadius: 3,
              borderDash: [],
            },
            {
              label: "Incoming",
              data: incoming,
              borderColor: "#1E90FF", 
              backgroundColor: "rgba(30, 144, 255, 0.2)",
              tension: 0.4,
              pointRadius: 3,
              borderDash: [],
            },
            {
              label: "Answered",
              data: answered,
              borderColor: "#5AC8BB",
              backgroundColor: "rgba(90, 200, 187, 0.2)",
              tension: 0.4,
              pointRadius: 3,
              borderDash: [],
            },
            {
              label: "Canceled",
              data: canceled,
              borderColor: "#F08733",
              backgroundColor: "rgba(240, 135, 51, 0.2)",
              tension: 0.4,
              pointRadius: 3,
              borderDash: [],
            },
          ],
        });
  
        // Set table data, even if empty
        // setCallData(result.length ? result : getDefaultTableData(labels));
        
      } else {
        // Handle case where response is not successful, but ensure chart and table still render
        const labels = getDefaultLabels();
        setChartData(getZeroedChartData(labels));
        setCallData(getDefaultTableData(labels));
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      const labels = getDefaultLabels();
      setChartData(getZeroedChartData(labels));
      setCallData(getDefaultTableData(labels));
    }
  };
  
  // Helper function to get default labels (for example, last 7 days)
  const getDefaultLabels = () => {
    const today = new Date();
    return Array.from({ length: 7 }, (_, i) => 
      format(subMonths(today, i), 'yyyy-MM-dd')
    ).reverse();
  };
  
  // Helper function to generate zeroed-out chart data
  const getZeroedChartData = (labels) => ({
    labels,
    datasets: [
      {
        label: "No Answered",
        data: labels.map(() => 0),
        borderColor: "#58BF6A",
        backgroundColor: "rgba(88, 191, 106, 0.2)",
        tension: 0.4,
        pointRadius: 3,
        borderDash: [],
      },
      {
        label: "Incoming",
        data: labels.map(() => 0),
        borderColor: "#1E90FF", 
        backgroundColor: "rgba(30, 144, 255, 0.2)",
        tension: 0.4,
        pointRadius: 3,
        borderDash: [],
      },
      {
        label: "Answered",
        data: labels.map(() => 0),
        borderColor: "#5AC8BB",
        backgroundColor: "rgba(90, 200, 187, 0.2)",
        tension: 0.4,
        pointRadius: 3,
        borderDash: [],
      },
      {
        label: "Canceled",
        data: labels.map(() => 0),
        borderColor: "#F08733",
        backgroundColor: "rgba(240, 135, 51, 0.2)",
        tension: 0.4,
        pointRadius: 3,
        borderDash: [],
      },
    ],
  });

  const getDefaultTableData = (labels) => {
    return labels.map(label => ({
      start_time: `${label}-00:00:00`,
      end_time: `${label}-23:59:59`,
      call_from: 'N/A',
      call_to: 'N/A',
      did: 'N/A',
      uniqueid: 'N/A',
      dur: '0',
      status: 'N/A',
      record_url: null,
    }));
  };

  
  const fetchCallDetails = async () => {
    try {
      const response = await CallGETAPI(`api/calls/user/${user_id}`);
      const result = response?.data?.data;
      if (response.status) {
        setCallData(result);
      } else {
        setCallData([]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setCallData([]); // Clear data in case of error
    }
  };

  useEffect(() => {
    fetchCallDetails();
  }, [user_id]);




   const handleFilterChange = (e) => {
    setFilter({
      ...filter,
      [e.target.name]: e.target.value
    });
  };


  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      fetchFilteredCalls();
    }, 300); // 300ms delay

    return () => clearTimeout(delayDebounceFn);
  }, [filter]);
  
  
  const fetchFilteredCalls = async () => {
    try {
      const queryParams = new URLSearchParams(filter).toString();
      const response = await CallGETAPI(`api/filtered-calls/${user_id}?${queryParams}`);

      if (response.status) {
        let filteredData = response.data.data;

        // Filter the data based on partial matches
        filteredData = filteredData.filter(item => {
          return (
            (filter.did === '' || item.did.toLowerCase().includes(filter.did.toLowerCase())) &&
            (filter.uniqueid === '' || item.uniqueid.toLowerCase().includes(filter.uniqueid.toLowerCase())) &&
            (filter.call_from === '' || item.call_from.toLowerCase().includes(filter.call_from.toLowerCase())) &&
            (filter.call_to === '' || item.call_to.toLowerCase().includes(filter.call_to.toLowerCase()))
          );
        });

        // Sort the filtered data
        filteredData.sort((a, b) => {
          for (const key in filter) {
            if (filter[key] && a[key].toLowerCase().includes(filter[key].toLowerCase())) {
              return -1;
            }
            if (filter[key] && b[key].toLowerCase().includes(filter[key].toLowerCase())) {
              return 1;
            }
          }
          return 0;
        });

        setCallData(filteredData);
      } else {
        setCallData([]);
      }
    } catch (error) {
      console.error("Error fetching filtered calls:", error);
      setCallData([]);
    }
  };
  
  
  


  useEffect(() => {
    fetchFilteredCalls();
  }, [filter]); // Fetch data whenever filter changes

  



  // const fetchCallDetails = async () => {
  //   try {
  //     const response = await CallGETAPI(`api/call-details-history/${sip}`);
  //     const result = response?.data?.callDetails;
  //     if (response.status) {
  //       setCallData(result);
  //     } else {
  //       setCallData([]);
  //     }
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   }
  // };

  useEffect(() => {
    fetchCallDetails();
  }, [user_id]);

  // const datesArray = [
  //   "0",
  //   "2",
  //   "3",
  //   "4",
  //   "5",
  //   "6",
  //   "7",
  //   "8",
  //   "9",
  //   "10",
  //   "11",
  //   "12",
  //   "13",
  //   "14",
  //   "15",
  //   "16",
  //   "17",
  //   "18",
  //   "19",
  //   "20",
  //   "21",
  //   "23",
  // ];
  // let currentData = {
  //   labels: datesArray,
  //   datasets: [
  //     {
  //       label: "Completed",
  //       data: [7, 6],
  //       borderColor: "#5AC8BB",
  //       backgroundColor: "#5AC8BB",
  //       tension: 0.5,
  //       pointRadius: 3,
  //       borderDash: [5, 5],
  //     },
  //     {
  //       label: "No Connect",
  //       data: [6, 5],
  //       borderColor: "#58BF6A",
  //       backgroundColor: "#58BF6A",
  //       tension: 0.5,
  //       pointRadius: 3,
  //       borderDash: [5, 5],
  //     },
  //     {
  //       label: "Connected",
  //       data: [4, 7],
  //       borderColor: "#F08733",
  //       backgroundColor: "#F08733",
  //       tension: 0.5,
  //       pointRadius: 3,
  //       borderDash: [5, 5],
  //     },
    
  //     {
  //       label: "Incoming",
  //       data: [4, 2],
  //       borderColor: "#F08733",
  //       backgroundColor: "#F08733",
  //       tension: 0.5,
  //       pointRadius: 3,
  //       borderDash: [5, 5],
  //     },
  //   ],
  // };

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );

  const options = {
    responsive: true,
    maintainAspectRatio: false, // Allow custom height
    // height: "1000px", // Set desired height in pixels
    // width: 300,
    plugins: {
      legend: {
        position: "top",
        display: false,
      },
      title: {
        display: false,
        text: "Daily Chart",
      },
    },
    scales: {
      y: {
        beginAtZero: true, // Y-axis starts from zero
      },
    },
  };

  let chart;

  // Export Image
  const downloadImage = () => {
    chart?.downloadImage();
  };

  // Get chart base64 string
  const toDataURL = () => {
    // console.log(chart?.toDataURL());
  };

  const [showFilterForm, setShowFilterForm] = useState(false);

  const openFilterForm = () => {
    setShowFilterForm(true);
  };

  const closeFilterForm = () => {
    setShowFilterForm(false);
  };

  const applyFilter = () => {
    // Add logic to handle filter application
    // For example, you can retrieve input values and perform filtering
    // You may also want to update the state or perform other actions
  };

  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTimeZone, setSelectedTimeZone] = useState("");
  const [selectedAdmin, setSelectedAdmin] = useState("");
  // const data = [];

  // Function to format the selected date and time with the selected time zone
  const formatSelectedDateTime = (date, timeZone) => {
    if (date) {
      return (
        <span>
          <FaCalendarAlt />{" "}
          {moment(date).tz(timeZone).format("MMMM D, YYYY h:mm A")}
        </span>
      );
    } else {
      return "Date";
    }
  };

  const callcolumns = [
    {
      name: "CALL DATE",
      selector: "start_time",
      sortable: true,
      cell: (d) => {
        // Ensure start_time is defined
        const date = d.start_time ? d.start_time.split('-').slice(0, 3).join('-') : 'N/A';
        return <span>{date}</span>;
      },
    },
    {
      name: "CALL TIME",
      selector: "end_time",
      sortable: true,
      cell: (d) => {
        // Ensure end_time is defined
        const time = d.end_time ? d.end_time.split('-')[3] : 'N/A';
        return <span>{time}</span>;
      }
    },
    
    
    // {
    //     name: "PUBLISHER",
    //     selector: "publisher",
    //     sortable: true,
    // },
    {
      name: "CALL FROM",
      selector: "call_from",
      sortable: true,
    },
    {
      name: "CALL TO",
      selector: "call_to",
      sortable: true,
      // cell: (d) => (
      //     <p class="align-text-bottom text-nowrap">
      //         {d.recording}
      //         <svg height={24}>
      //             <circle cx="12" cy="12" r="5" fill="green" />
      //         </svg>
      //     </p>
      // )
    },
    // {
    //   name: "BUYER",
    //   selector: "buyer", 
    // },
    // {
    //   name: "INBOUND CALL ID",
    //   selector: "inboundcallid",
    // },
    {
      name: "DID",
      selector: "did",
    },
    {
      name: "UNIQUEI ID",
      selector: "uniqueid",
    },
    // {
    //   name: "NUMBER",
    //   selector: "src",
    // },
    {
      name: "TIME DURATION",
      center: true,
      selector: "dur",
      cell: (d) => {
        // Get the duration value and ensure it's a number
        const duration = parseInt(d.dur, 10); 
    
        // Default to 0 if the duration is NaN or not a valid number
        if (isNaN(duration) || duration === 0) {
          return <span>0 sec</span>;
        } else if (duration < 60) {
          return <span>{duration} sec</span>;
        } else {
          const hours = Math.floor(duration / 3600);
          const minutes = Math.floor((duration % 3600) / 60);
          const seconds = duration % 60;
    
          let formattedDuration = '';
          if (hours > 0) {
            formattedDuration += `${hours} hr `;
          }
          if (minutes > 0 || hours > 0) {
            formattedDuration += `${minutes} min `;
          }
          formattedDuration += `${seconds} sec`;
    
          return <span>{formattedDuration.trim()}</span>;
        }
      }
    },

    
    
    
    {
      name: "RECORDING",
      center: true,
      sortable: false,
      selector: "record_url",
      cell: (row) => {
       
        const recordingUrl = FILE_BASE_RECORDING + "/callforward/" ;

        return (
          <audio controls>
            <source src={recordingUrl} type="audio/mpeg" />
            Your browser does not support the audio element.
          </audio>
        );
      },
    },

    {
      name: "STATUS",
      selector: "status",
    },

   
    {
      name: "ACTION",
      center: true,
      sortable: false,
      selector: "null",
      cell: () => [
        <button type="submit" class="btn btn-sm btn-outline-warning">
            <i class="fa-solid fa-xmark"></i>
          </button>,
      ]
    }
];

const [countries, setCountries] = useState([]);
const [selectedCountry, setSelectedCountry] = useState('');
const [timezones, setTimezones] = useState([]);
const [isCountryError, setIsCountryError] = useState(false);


useEffect(() => {
  fetchCountries();
}, []);

// Fetch Countries Function
const fetchCountries = async () => {
  try {
    const response = await CallGETAPI('api/country-list');
    console.log(response, "Country data fetch");
    
    // Access the `data` array correctly from the response
    const countriesData = response.data.data; 

    if (response.status && Array.isArray(countriesData)) {
      setCountries(countriesData);
      console.log("Countries set successfully", countriesData);
    } else {
      console.error('Expected an array from API:', response);
    }
  } catch (error) {
    console.error('Error fetching countries:', error);
  }
};

const handleCountrySelect = async (e) => {
  const countryId = e.target.value;
  setSelectedCountry(countryId);

  const resultData = await GetTimeZoneById(countryId);
  setTimezones(resultData || []);

  // Set error flag if countryId is not selected
  setIsCountryError(!countryId);
};


const onChange = (value) => {
  console.log('Selected Time:', value);
};

const onOk = (value) => {
  console.log('onOk:', value);
};
  return (
    <main id="mains" className="mains">
      <section>
      <div className="card">
          <div className="card-body">
            <h3>Date Range By filter</h3>
            <div className="filter-section">
              <div className="custom-select-container">
                {/* <select
                  className="form-select w-75"
                  value={selectedCountry}
                  onChange={handleCountrySelect}
                  name="country"
                >
                  <option value="">Select Country</option>
                  {countries.length > 0 ? (
                    countries.map((country) => (
                      <option key={country.iso} value={country.iso}>
                        {country.nicename}
                      </option>
                    ))
                  ) : (
                    <option>Loading...</option>
                  )}
                </select> */}

                {selectedCountry && (
                  <Form.Select
                    className="custom-select"
                    name="timezone"
                  >
                    <option value="">Choose a Timezone</option>
                    {Array.isArray(timezones) &&
                      timezones.map((timezone) => (
                        <option key={timezone.timezone} value={timezone.timezone}>
                          {timezone.timezone}
                        </option>
                      ))}
                  </Form.Select>
                )}
                <div className="date-picker-container">
                  <Space direction="vertical" size={12}>
                  <RangePicker
                 selected={[startDate, endDate]}
                 format="YYYY-MM-DD"
                 onChange={handleDateRangeChange}
               />
                  </Space>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="card">
          <div className="card-body">
            {showFilterForm ? (
              <form>
                <label htmlFor="filterInput">Filter Input:</label>
                <input type="text" id="filterInput" className="form-control" />
                <button type="button" className="btn btn-primary" onClick={applyFilter}>
                  Apply Filter
                </button>
                <button type="button" className="btn btn-secondary ml-2" onClick={closeFilterForm}>
                  Close
                </button>
              </form>
            ) : (
              <button type="button" className="btn btn-sm btn-outline-dark" onClick={openFilterForm}>
                +Filter
              </button>
            )}
          </div>
        </div> */}
        <div className="card">
          <div className="card-body">
            <div>
              <div>TimeLine </div>
              {/* <button
                type="button"
                onClick={downloadImage}
                style={{ marginRight: 24 }}
              >
                Export Image
              </button>
              <button type="button" onClick={toDataURL}>
                Get base64
              </button> */}
              {/* <Line
                {...config}
                onReady={(chartInstance) => (chart = chartInstance)}
              /> */}
              <div style={{ width: "100%",height: "300px" }} className="canvas-handle">
              {chartData ? (
                <Line options={options} data={chartData} />
              ) : (
                <p>No data available for the selected date range</p>
              )}
            </div>
            </div>
          </div>
        </div>

        <div className="card">
          <div className="card-body">
            {/* Bordered Tabs Justified */}
            {/* <div>
            
              <ul
                className="nav nav-tabs nav-tabs-bordered d-flex"
                id="borderedTabJustified"
                role="tablist"
              >
                <li className="nav-item flex-fill" role="presentation">
                  <button
                    className="nav-link w-100 active"
                    id="campaign-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#bordered-justified-campaign"
                    type="button"
                    role="tab"
                    aria-controls="campaign"
                    aria-selected="true"
                  >
                    Campaign
                  </button>
                </li>
                <li className="nav-item flex-fill" role="presentation">
                  <button
                    className="nav-link w-100"
                    id="publisher-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#bordered-justified-publisher"
                    type="button"
                    role="tab"
                    aria-controls="publisher"
                    aria-selected="false"
                  >
                    Publisher
                  </button>
                </li>
                <li className="nav-item flex-fill" role="presentation">
                  <button
                    className="nav-link w-100"
                    id="buyer-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#bordered-justified-buyer"
                    type="button"
                    role="tab"
                    aria-controls="buyer"
                    aria-selected="false"
                  >
                    Buyer
                  </button>
                </li>
                <li className="nav-item flex-fill" role="presentation">
                  <button
                    className="nav-link w-100"
                    id="target-tab" b
                    data-bs-toggle="tab"
                    data-bs-target="#bordered-justified-target"
                    type="button"
                    role="tab"
                    aria-controls="target"
                    aria-selected="false"
                  >
                    Target
                  </button>
                </li>
              </ul>
            </div> */}

            <div className="tab-content pt-2" id="borderedTabJustifiedContent">
              <div
                className="tab-pane fade show active"
                id="bordered-justified-campaign"
                role="tabpanel"
                aria-labelledby="campaign-tab"
              >
                <div className="card" style={{ boxShadow: "none" }}>
                  <div className="card-body" style={{ padding: 0 }}>
                    <div className="main">
                      {/* <DataTableExtensions {...tableData}> */}
                      {/* <DataTable
                        columns={campaigncolumns}
                        data={campaigndata}
                        noHeader
                        defaultSortField="id"
                        // sortIcon={<SortIcon />}
                        defaultSortAsc={true}
                        pagination
                        highlightOnHover
                        dense
                      /> */}
                      {/* </DataTableExtensions> */}
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="tab-pane fade"
                id="bordered-justified-publisher"
                role="tabpanel"
                aria-labelledby="publisher-tab"
              >
                <div className="card" style={{ boxShadow: "none" }}>
                  <div className="card-body" style={{ padding: 0 }}>
                    <div className="main">
                      {/* <DataTableExtensions {...tableData}> */}
                      {/* <DataTable
                        columns={publishercolumns}
                        data={publisherdata}
                        noHeader
                        defaultSortField="id"
                        // sortIcon={<SortIcon />}
                        defaultSortAsc={true}
                        pagination
                        highlightOnHover
                        dense
                      /> */}
                      {/* </DataTableExtensions> */}
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade "
                id="bordered-justified-buyer"
                role="tabpanel"
                aria-labelledby="buyer-tab"
              >
                <div className="card" style={{ boxShadow: "none" }}>
                  <div className="card-body" style={{ padding: 0 }}>
                    <div className="main">
                      {/* <DataTableExtensions {...tableData}> */}
                      <DataTable
                        columns={buyercolumns}
                        data={buyerdata}
                        noHeader
                        defaultSortField="id"
                        // sortIcon={<SortIcon />}
                        defaultSortAsc={true}
                        pagination
                        highlightOnHover
                        dense
                      />
                      {/* </DataTableExtensions> */}
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="tab-pane fade "
                id="bordered-justified-target"
                role="tabpanel"
                aria-labelledby="target-tab"
              >
                <div className="card" style={{ boxShadow: "none" }}>
                  <div className="card-body" style={{ padding: 0 }}>
                    <div className="main">
                      {/* <DataTableExtensions {...tableData}> */}
                      <DataTable
                        columns={targetcolumns}
                        data={targetdata}
                        noHeader
                        defaultSortField="id"
                        // sortIcon={<SortIcon />}
                        defaultSortAsc={true}
                        pagination
                        highlightOnHover
                        dense
                      />
                      {/* </DataTableExtensions> */}
                    </div>
                  </div>
                </div>
              </div>

              {/* Repeat the above code for the other tabs */}
            </div>
            {/* End Bordered Tabs Justified */}
          </div>
        </div>
        <div className="card">
          <div className="card-body">
            <h3>Filter Calls</h3>
            <div className="filter-section">
              <div className="row">
                <div className="col">
                  <input
                    type="text"
                    name="did"
                    placeholder="Filter by DID"
                    value={filter.did}
                    onChange={handleFilterChange}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <input
                    type="text"
                    name="uniqueid"
                    placeholder="Filter by Unique ID"
                    value={filter.uniqueid}
                    onChange={handleFilterChange}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <input
                    type="text"
                    name="call_from"
                    placeholder="Filter by Call From"
                    value={filter.call_from}
                    onChange={handleFilterChange}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <input
                    type="text"
                    name="call_to"
                    placeholder="Filter by Call To"
                    value={filter.call_to}
                    onChange={handleFilterChange}
                    className="form-control"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="card">
          <div className="card-body">
            <div className="main">
              <h5>Call Details</h5>
              {/* <DataTableExtensions {...tableData}> */}
              <DataTable
                columns={callcolumns}
                data={callData}
                noHeader
                defaultSortField="id"
                // sortIcon={<SortIcon />}
                defaultSortAsc={true}
                pagination
                highlightOnHover
                dense
              />
              {/* </DataTableExtensions> */}
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default ReportList;
