import React, { useEffect, useState } from "react";
import Chart from "chart.js/auto";
import { ToastContainer } from "react-toastify";
 import { useRef } from "react";
import { Line, Bar } from "react-chartjs-2";
import CallSummary from "./CallSummary";
import CallDetails from "./CallDetails";
import { Link } from "react-router-dom";
const data1 = [
  { title: "Total Calls Made", value: 166, info: "" },
  { title: "Total Users Called", value: 4, info: "" },
  { title: "Best Performer", value: "David", info: "" },
];

const data2 = [
  { title: "Live Calls", value: 0, info: "" },
  { title: "Calls in Queue", value: 0, info: "" },
  { title: "Available Agents", value: 0, info: "/3" },
];

function Dashboard() {
  const [barChartCanvas, setBarChartCanvas] = useState(null);

  // useEffect(() => {
  const chartData = {
    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
    datasets: [
      {
        label: "First dataset",
        data: [33, 53, 85, 41, 44, 65],
        fill: true,
        backgroundColor: "rgba(75,192,192,0.2)",
        borderColor: "rgba(75,192,192,1)",
      },
      {
        label: "Second dataset",
        data: [33, 25, 35, 51, 54, 76],
        fill: false,
        borderColor: "#742774",
      },
    ],
  };

  const barData = {
    labels: ["January", "February", "March", "April", "May", "June", "July"],
    datasets: [
      {
        label: "Bar Chart",
        data: [65, 59, 80, 81, 56, 55, 40],
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(255, 159, 64, 0.2)",
          "rgba(255, 205, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(201, 203, 207, 0.2)",
        ],
        borderColor: [
          "rgb(255, 99, 132)",
          "rgb(255, 159, 64)",
          "rgb(255, 205, 86)",
          "rgb(75, 192, 192)",
          "rgb(54, 162, 235)",
          "rgb(153, 102, 255)",
          "rgb(201, 203, 207)",
        ],
        borderWidth: 1,
      },
    ],
    options: {
      scales: {
        y: {
          beginAtZero: true,
        },
      },
    },
  };

  return (
    <>
      <ToastContainer />
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Dashboard</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link href="/dashboard">Home</Link>
              </li>
              <li className="breadcrumb-item active">Dashboard</li>
            </ol>
          </nav>
        </div>

        <section className="section dashboard">
          <div className="row">
            <div className="col-lg-4">
              <div className="App">
                <CallDetails title="Total Users" value="4" />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="App">
                <CallDetails title="Total Number" value="166" />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="App">
                <CallDetails title="Free Incoming Call" value="0" />
              </div>
            </div>
          </div>
        </section>

        <section className="section dashboard">
          <div className="row">
            <div className="col-lg-6">
              <div className="App" >
                <CallSummary />
              </div>
            </div>
            <div className="col-lg-6">
              <CallSummary />
            </div>
          </div>
        </section>

        <section className="section dashboard">
          <div className="row">
            <div className="col-lg-6">
              <div className="App" style={{ background: "white" }}>
                <Line data={chartData} />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="App" style={{ background: "white" }}>
                <Bar data={barData} />
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default Dashboard;
