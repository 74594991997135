import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
 import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { Button } from "react-bootstrap";
import { useAuth } from "../AuthRouter/AuthContext";
import { CallPOSTAPI, CallGETAPI } from "../../helper/Constants";

const SignInForm = () => {
  const navigate = useNavigate();
  const { login } = useAuth();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    remember: false,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [remember, setRemember] = useState(false);
  const [errors, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);

  const handlePasswordToggle = () => {
    setShowPassword(!showPassword);
  };

  // const handleChange = (e) => {
  //   if (e.target.type === "checkbox") {
  //     setFormData({ ...formData, [e.target.name]: e.target.checked });
  //   } else {
  //     setFormData({ ...formData, [e.target.name]: e.target.value });
  //   }
  // };

  const validateForm = () => {
    const errors = {};

    if (!email.trim()) {
      errors.email = "Email is required !";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      errors.email = "Email address is invalid !";
    }

    if (!password.trim()) {
      errors.password = "Password is required !";
    }

    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formErrors = validateForm();
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return;
    }
    setIsLoading(true);

    try {
      const response = await CallPOSTAPI("api/login", formData);
      // console.log({response});
      // console.log("Response Data:", response.data);

      if (response?.data?.status) {
        let token = response?.data?.token;
        localStorage.setItem("psx_token", token);
        toast.success("Login Successful");
        await CallGETAPI("api/connect");
        login();
        navigate("/dashboard");
      } else {
        toast.error(response?.data?.message);
      }
    } catch (error) {
      console.error("Form submission error:", error);
      toast.error("An unexpected error occurred. Please try again later.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleEmailChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setEmail(e.target.value);
    setErrors((prevErrors) => ({
      ...prevErrors,
      email: "",
    }));
  };

  const handlePasswordChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setPassword(e.target.value);
    setErrors((prevErrors) => ({
      ...prevErrors,
      password: "",
    }));
  };

  const handleRememberChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.checked });
    setRemember(e.target.checked);
  };

  return (
    <div className="custom-design">
      {" "}
      <div className="form-container sign-in-container">
        <form onSubmit={handleSubmit}>
          <h1>Sign in</h1>
          <div className="social-container">
            <a href="#" className="social">
              <i className="fab fa-facebook-f" />
            </a>
            <a href="#" className="social">
              <i className="fab fa-google-plus-g" />
            </a>
            <a href="#" className="social">
              <i className="fab fa-linkedin-in" />
            </a>
          </div>
          <span>or use your account</span>
          <input
            type="email"
            placeholder="Email"
            name="email"
            value={email}
            onChange={handleEmailChange}
            className={`${errors.email ? "is-invalid" : ""}`}
          />
          <div className="invalid-feedback">{errors.email}</div>
          <div className="input-group">
            <input
              type={showPassword ? "text" : "password"}
              name="password"
              placeholder="Password"
              value={password}
              onChange={handlePasswordChange}
              // className={`form-control ${errors.password ? "is-invalid" : ""}`}
              className="form-control "
            />
            <span
              className="input-group-text"
              onClick={handlePasswordToggle}
              style={{ cursor: "pointer", margin: "8px -3px" }}
            >
              <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash} />
            </span>
          </div>
          <div className="invalid-feedback">{errors.password}</div>
          {/* <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            name="remember"
            checked={remember}
            onChange={handleRememberChange}
            id="rememberMe"
          />
          <label className="form-check-label" htmlFor="rememberMe">
            Remember me
          </label>
        </div> */}
          <Link to="/forget_password">Forgot your password?</Link>
          <button disabled={isLoading}>
            {isLoading ? "Logging in..." : "Sign In"}
          </button>
        </form>
        {isLoading && <div className="loading-spinner"></div>}
        {/* <ToastContainer /> */}
      </div>
    </div>
  );
};

export default SignInForm;
