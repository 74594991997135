import React,{useState,useEffect} from 'react';
import { CallGETAPI } from '../../helper/Constants';
import DataTable from 'react-data-table-component';
import { tableCustomStyles } from '../../helper/utils';

import {  CONNECT_STATUS, useSIPProvider } from "react-sipjs";
import { toast } from 'react-toastify';
import { BASE_DOMAIN } from './constant';
// import AriWebSocket from '../websocket/AriWebSocket.js';

const LiveCallTbl = ({data})=>{
    const adminUserId = "admin";
    const {
        sessionManager,
        connectStatus,
      } = useSIPProvider();


      const BargeCall = async (ext) => {

        if (connectStatus !== CONNECT_STATUS.CONNECTED) {
          toast.error("You do not connect with any sip.");
          return;
        }
        // console.log(`sip:552${ext}@${BASE_DOMAIN}`);
        await sessionManager?.call(`sip:552${ext}@${BASE_DOMAIN}`, {});
      };
    
      const WhisperCall = async (ext) => {
        if (connectStatus !== CONNECT_STATUS.CONNECTED) {
          toast.error("You do not connect with any sip.");
          return;
        }
        await sessionManager?.call(`sip:553${ext}@${BASE_DOMAIN}`, {});
      };
    
      const ListenCall = async (ext) => {
        if (connectStatus !== CONNECT_STATUS.CONNECTED) {
          toast.error("You do not connect with any sip.");
          return;
        }
        await sessionManager?.call(`sip:551${ext}@${BASE_DOMAIN}`, {});
      };

    const [isLoading, setIsLoading] = useState(false);
    // const [data, setData] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
  
    // const fetchData = async () => {
    //     try {
    //       setIsLoading(true);
    //       const response = await CallGETAPI("api/live-calls");
    //       // console.log("Data from API:", response?.data?.liveCalls);
    //       const result = response?.data?.liveCalls || [];
    //       setData(result);
    //       setIsLoading(false);
    //     } catch (error) {
    //       // console.log(error.message);
    //       console.error("Error fetching data:", error);
    //       setIsLoading(false);
    //     }
    //   };
    
    //   // Fetch data on component mount
    //   useEffect(() => {
    //     fetchData();
    //   }, []);

      const Live_calls_data = [
        {
          name: "ACTION",
          center: true,
          sortable: false,
          minWidth: '310px',
          selector: "null",
          cell: (row) => [
            <button
              type="button"
              className="btn btn-sm btn-outline-warning"
              onClick={() => BargeCall(row.called_to.ext_number)}
            >
              Barge Call
            </button>,
            <button
              type="submit"
              className="btn btn-sm btn-outline-warning mx-2"
              onClick={() => WhisperCall(row.called_to.ext_number)}
            >
              Whisper Call
            </button>,
            <button
              type="submit"
              className="btn btn-sm btn-outline-warning"
              onClick={() => ListenCall(row.called_to.ext_number)}
            >
              Listen Call
            </button>,
          ],
        },
        {
          name: "From",
          sortable: true,
          selector: (row) => (row.call_from ? row.call_from.callerId : ""),
        },
        {
          name: "To",
          selector: (row) => (row.called_to ? row.called_to.ext_number : ""),
          sortable: true,
        },
        {
          name: "Extensions",
          sortable: true,
          selector: (row) => {
            const calledFrom = row.call_from
              ? row.call_from.callerId
              : "Not Available";
            const calledTo = row.called_to
              ? row.called_to.ext_number
              : "Not Available";
            return `${calledFrom} to ${calledTo}`;
          },
        },
        // Add the new column for Duration
        // {
        //   name: "Duration",
        //   selector: "duration",
        //   sortable: true,
        // },
      ];
    
      const filteredData = data?.filter((item) => {
        return Object.values(item).some((value) =>
          String(value).toLowerCase().includes(searchTerm.toLowerCase())
        );
      });
    
    


    

    return (
        <>

        <div className="datatable">
          <pre>
          {/* {JSON.stringify(filteredData)} */}
          </pre>
            <DataTable
                columns={Live_calls_data}
                data={filteredData}
                noHeader
                defaultSortField="id"
                // sortIcon={<SortIcon />}
                defaultSortAsc={true}
                pagination
                highlightOnHover
                // dense
                overflowY
                customStyles={tableCustomStyles}
            />
            </div>
            {/* <table className='table table-responsive ' style={{width:'100%'}}>
                <tr>
                    <th>From</th>
                    <th>To</th>
                    <th>Ext.</th>
                    <th>Action</th>
                </tr>
                <tbody>
                    {isData?.map((it)=>(
                        <tr>
                            <td>{it.call_from.channel}</td>
                            <td>{it.called_to.channel}</td>
                            <td>{it.call_from.callerId} to {it.called_to.callerId}</td>
                            <td><button className='btn btn-info' onClick={()=>bargeCall(it.call_from.callerId)} >Barge Call</button></td>
                            <td><button className='btn btn-info' onClick={()=>whisperCall(it.call_from.callerId)} >Whisper Call</button></td>
                            <td><button className='btn btn-info' onClick={()=>listenCall(it.call_from.callerId)} >Listen Call</button></td>
                        </tr>
                    ))}
                </tbody>
            </table> */}
        </>
    )
}
export default LiveCallTbl;