import React from "react";
import { Typography } from "@mui/material";
import moment from "moment-timezone";
import CallMadeIcon from "@mui/icons-material/CallMade"; // Outgoing call icon
import CallReceivedIcon from "@mui/icons-material/CallReceived"; // Incoming call icon
import MissedCallIcon from "@mui/icons-material/PhoneMissed"; // Missed call icon

const formatDuration = (duration) => {
  if (duration === 0) {
    return <span>N/C</span>;
  } else if (duration < 60) {
    return <span>{duration} sec</span>;
  } else {
    const hours = Math.floor(duration / 3600);
    const minutes = Math.floor((duration % 3600) / 60);
    const seconds = duration % 60;

    let formattedDuration = "";
    if (hours > 0) {
      formattedDuration += `${hours} hr `;
    }
    if (minutes > 0 || hours > 0) {
      formattedDuration += `${minutes} min `;
    }
    formattedDuration += `${seconds} sec`;

    return <span>{formattedDuration.trim()}</span>;
  }
};

const formatDateTime = (dateString) => {
  const date = moment(dateString).tz("Asia/Kolkata").subtract(5, 'hours').subtract(30, 'minutes');
  return `${date.format("DD/MM/YYYY")} (${date.format("hh:mm A")})`;
};

const getCallIcon = (data) => {
  if (data.disposition === "ANSWERED") {
    if (data.dst) {
      return <CallReceivedIcon style={{ color: "blue" }} />;
     // Outgoing call
    } else if (data.src ) {
      return <CallMadeIcon style={{ color: "green" }} />; // Incoming call
    }
  } else if (data.disposition === "NO ANSWER" || data.disposition === "BUSY") {
    return <MissedCallIcon style={{ color: "red" }} />; // Missed call
  }
  return null;
};

const TableComponent1 = (props) => {
  const { data  } = props; // Assuming  is passed as a prop

  return data ? (
    <div className="table-container">
      <table className="table">
        <tbody>
          <tr>
            <td className="text-start">
              <div className="text-#FFFF fw-bold">{data.src}</div>
              <Typography
                variant="body2"
                sx={{ color: "#374151", marginTop: "5px" }}
              >
                {formatDuration(data.duration)}
              </Typography>
            </td>
            <td className="text-end">
              <div
                style={{
                  display: "flex",
                  flexGrow: "0",
                  flexShrink: "0",
                  flexDirection: "column",
                  alignItems: "flex-end",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "6px",
                  }}
                >
                  {getCallIcon(data, )}
                  <Typography variant="caption" sx={{ color: "#6B7280" }}>
                    {data.disposition}
                  </Typography>
                </div>
                <Typography
                  variant="body2"
                  sx={{ color: "#374151", marginTop: "5px" }}
                >
                  {formatDateTime(data.calldate)}
                </Typography>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  ) : null;
};

export default TableComponent1;
