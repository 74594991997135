import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { CallGETAPI, CallPOSTAPI } from "../helper/Constants";

export const fetchBalance = createAsyncThunk(
  "wallet/fetchBalance",
  async (thunkAPI) => {
    try {
      const response = await CallGETAPI(`api/get-user-info`);
      // console.log(response);
      return {
        balance: response?.data?.data?.balance,
        firstName: response?.data?.data?.first_name,
        extension_number: response?.data?.combinedRecords?.id,
        mobile_numbers_purchase: response?.data?.is_have_number?.length || 0,
        userId: response?.data?.data?.user_id, // Added userId
        endDate:response?.data?.data?.endDate
      };
    } catch (error) {
      return thunkAPI.rejectWithValue("Failed to fetch balance");
    }
  }
);

export const fetchSipUserData = createAsyncThunk(
  "sip/fetchSipUserData",
  async (_, thunkAPI) => {
    try {
      const response = await CallGETAPI(`api/get-user-extension`);
      return response.data.data.id;
    } catch (error) {
      return thunkAPI.rejectWithValue("Failed to fetch SIP user data");
    }
  }
);


export const updateCallBalance = createAsyncThunk(
  "wallet/updateCallBalance",
  async ({ amount }, thunkAPI) => {
    try {
      const response = await CallPOSTAPI(`api/update-call-balance`, { amount });
      return response.data.currentBalance;
    } catch (error) {
      return thunkAPI.rejectWithValue("Failed to update balance during call");
    }
  }
);


export const createPhoneNumber = createAsyncThunk(
  "phoneNumber/createPhoneNumber",
  async ({ number, price }, thunkAPI) => {
    try {
      const response = await CallPOSTAPI("api/create-purchase-number-v2", {
        number,
        price
      });

      return response.data;
    } catch (error) {
      if (error.message === "Insufficient balance") {
        return thunkAPI.rejectWithValue(
          "Insufficient balance. Please add funds to your wallet."
        );
      } else {
        return thunkAPI.rejectWithValue("Failed to create phone number");
      }
    }
  }
);

const walletSlice = createSlice({
  name: "wallet",
  initialState: {
    balance: 0,
    firstName: "",
    extension_number: "",
    connectionStatus: "disconnected",
    liveCalls: {}, // Object to store live calls count by user_id
    status: "idle",
    userId: 0, // Added userId
    endDate: "" 
  },
  reducers: {
    addMoney: (state, action) => {
      state.balance = parseInt(state.balance) + parseInt(action.payload);
    },
    spendMoney: (state, action) => {
      state.balance = parseInt(state.balance) - parseInt(action.payload);
    },
    updatedMoney: (state, action) => {
      state.balance = action.payload;
    },
    
    updateConnectionStatus: (state, action) => {
      state.connectionStatus = action.payload;
    },
    incrementLiveCalls: (state, action) => {
      const userId = action.payload.userId;
      if (!state.liveCalls[userId]) {
        state.liveCalls[userId] = 0;
      }
      state.liveCalls[userId] += 1;
    },
   decrementLiveCalls: (state, action) => {
      const userId = action.payload.userId;
      if (state.liveCalls[userId]) {
        state.liveCalls[userId] -= 1;
        state.liveCalls[userId] = Math.max(state.liveCalls[userId], 0); // Ensure count doesn't go below 0
      }
    },
    
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBalance.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchBalance.fulfilled, (state, action) => {
        state.balance = action.payload.balance;
        state.firstName = action.payload.firstName;
        state.extension_number = action.payload.extension_number;
        state.userId = action.payload.userId;
        state.endDate = action.payload.endDate;
        state.status = "idle";
        
      })
      .addCase(fetchBalance.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(fetchSipUserData.fulfilled, (state, action) => {
        state.extension_number = action.payload;
      })
      .addCase(updateCallBalance.fulfilled, (state, action) => {
        state.balance = action.payload;
      })
      .addCase(createPhoneNumber.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createPhoneNumber.fulfilled, (state, action) => {
        state.status = "idle";
        state.extension_number = action.payload.extension_number; // Update with the new extension number
      })
      .addCase(createPhoneNumber.rejected, (state, action) => {
        state.status = "failed";
      });
  },
});

export const {
  updatedMoney,
  addMoney,
  spendMoney,
  updateConnectionStatus,
  incrementLiveCalls,
  decrementLiveCalls,
} = walletSlice.actions;

export default walletSlice.reducer;
