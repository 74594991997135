import React from "react";
import "./CallSummary.css"; // Import your CSS file

function CallSummary() {
  return (
    <div className="call-summary">
      <div className="summary-header">
        <i className="tio-user-big"></i>
        <h4 style={{ fontSize: "18px" }}>Call Summary</h4>
      </div>

      <div className="table-row header-row data">
        <p className="data1">166</p>
        <p className="data2">4</p>
        <p>David</p>
      </div>
      <div className="table-row">
        <p>Total Calls Made</p>
        <p>Total Users Called</p>
        <p>Best Performer</p>
      </div>
    </div>
  );
}

export default CallSummary;
