import { FILE_BASE_RECORDING } from "../../helper/Constants";
import "./Dialpad.css";
import ReactAudioPlayer from "react-audio-player";

const TableComponent2 = (props) => {
  const { recording } = props;
  if (!recording) {
    return null;
  }

  // Remove "50.19.138.104" from recording file URL
  // // console.log({ checking: recording.recordingfile, recording });
  const record = recording?.recording;
  const recordingFileName = record.recordingfile
    ? record.recordingfile.substring(record.recordingfile.lastIndexOf("/") + 1)
    : "";

  // // console.log(recordingFileName);

  // Format the call date to DD/MM/YYYY
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const formattedDate = recording.calldate ? formatDate(recording.calldate) : "";

  return recording ? (
    <div className="container mt-3">
      <table className="table">
        <tbody>
          <tr>
            <td className="text-start mb-3">
              <div className="d-flex justify-content-between">
                <div>{recording.dst}</div>
                <strong>{formattedDate}</strong>
              </div>
              <ReactAudioPlayer
                className="w-100"
                src={FILE_BASE_RECORDING + 'recording/' + recordingFileName}
                controls
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  ) : null;
};

export default TableComponent2;
