import React, { useEffect, useState } from "react";
import Header from "../Header";
import Sidebar from "../Sidebar";
import EndSidebar from "../EndSidebar";
import { useDispatch, useSelector } from "react-redux";
import { fetchBalance, fetchSipUserData, incrementLiveCalls, decrementLiveCalls } from "../../slices/WalletSlice";
import HandleCallCenter from "./HandleCallCenter";
import { BASE_DOMAIN } from "../phone/constant";
import { SIPProvider } from "react-sipjs";
import io from "socket.io-client";

const Layout = (props) => {
  const dispatch = useDispatch();
  const [openNav, setOpenNav] = useState(false);
  const sip = useSelector((state) => state.wallet.extension_number);

  const OpenNavHandler = () => {
    setOpenNav(!openNav);
  };

  useEffect(() => {
    const body = document.querySelector("body");
    if (openNav) {
      body.classList = "toggle-sidebar";
    } else {
      body.classList = "";
    }
  }, [openNav]);

  useEffect(() => {
    dispatch(fetchBalance());
    dispatch(fetchSipUserData());
  }, []);

  useEffect(() => {
    let socket;

    if (sip) {
      socket = io(window.FRONT_WEB_SOCKET_URL);

      socket.on("newCall", (callInfo) => {
        // console.log({ callInfo });
        dispatch(incrementLiveCalls({ userId: callInfo.userId }));
      });

      socket.on("disconnectCall", (callInfo) => {
        // console.log("call disconnected")
        // console.log({ callInfo });
        dispatch(decrementLiveCalls({ userId: callInfo.userId }));
      });

      return () => {
        socket.off("newCall");
        socket.off("disconnectCall");
        socket.disconnect();
      };
    }
  }, [dispatch, sip]);

  return (
    <>
      <SIPProvider options={{ domain: BASE_DOMAIN, webSocketServer: window.VOIP_WS_URL }}>
        <Header openNav={openNav} navHandler={OpenNavHandler} sip={sip} />
        <Sidebar openNav={openNav} navHandler={OpenNavHandler} />
        <EndSidebar openNav={openNav} navHandler={OpenNavHandler} />
        <HandleCallCenter />
        <div className="">
          {props.children}
        </div>
      </SIPProvider>
    </>
  );
};

export default Layout;
