import React, { useState } from "react";
import { CallGETAPI } from "../helper/Constants";
import DataTable from "react-data-table-component";
import { tableCustomStyles } from "../helper/utils";
import { useEffect } from "react";
import ReactLoading from "react-loading";

function LiveSummary({ bargeCall, whisperCall, listenCall }) {
  const [activeTab, setActiveTab] = useState("live-calls");
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const fetchData = async () => {
    try {
      // setIsLoading(true);
      const response = await CallGETAPI("api/live-calls");
      // console.log("Data from API:", response?.data?.liveCalls);
      const result = response?.data?.liveCalls || [];
      setData(result);
      setIsLoading(false);
    } catch (error) {
      // console.log(error.message);
      console.error("Error fetching data:", error);
      setIsLoading(false);
    }
  };

  // Fetch data on component mount
  useEffect(() => {
    // setInterval(() => {
    //   fetchData();  
    // }, 5000);
  }, []);

  const Live_calls_data = [
    {
      name: "ACTION",
      center: true,
      sortable: false,
      selector: "null",
      cell: (row) => [
        <button
          type="button"
          className="btn btn-sm btn-outline-warning"
          onClick={() => handleBargeCall(row.call_from.callerId)}
        >
          Barge Call
        </button>,
        <button
          type="submit"
          className="btn btn-sm btn-outline-warning"
          onClick={() => handleWhisperCall(row.call_from.callerId)}
        >
          Whisper Call
        </button>,
        <button
          type="submit"
          className="btn btn-sm btn-outline-warning"
          onClick={() => handleReceiveCall(row.call_from.callerId)}
        >
          Receive Calls
        </button>,
      ],
    },
    {
      name: "From",
      sortable: true,
      selector: (row) => (row.call_from ? row.call_from.channel : ""),
    },
    {
      name: "To",
      selector: (row) => (row.called_to ? row.called_to.channel : ""),
      sortable: true,
    },
    {
      name: "Extensions",
      sortable: true,
      selector: (row) => {
        const calledFrom = row.call_from
          ? row.call_from.callerId
          : "Not Available";
        const calledTo = row.called_to
          ? row.called_to.callerId
          : "Not Available";
        return `${calledFrom} to ${calledTo}`;
      },
    },
    // Add the new column for Duration
    {
      name: "Duration",
      selector: "duration",
      sortable: true,
    },
  ];

  const handleBargeCall = (row) => {
    // Add logic for barge call
    // console.log("Barge call initiated for row:", row);
  };

  const handleWhisperCall = (row) => {
    // Add logic for whisper call
    // console.log("Whisper call initiated for row:", row);
  };

  const handleReceiveCall = (row) => {
    // Add logic for receive call
    // console.log("Receive call initiated for row:", row);
  };

  const filteredData = data?.filter((item) => {
    return Object.values(item).some((value) =>
      String(value).toLowerCase().includes(searchTerm.toLowerCase())
    );
  });

  const handleTabChange = (tabId) => {
    setActiveTab(tabId);
  };

  return (
    <main id="main" className="main">
      <section>
        <div className="card">
          <div className="card-body">
            <ul
              className="nav nav-tabs nav-tabs-bordered d-flex"
              id="borderedTabJustified"
              role="tablist"
            >
              <li className="nav-item flex-fill" role="presentation">
                <button
                  className={`nav-link w-100 ${
                    activeTab === "live-calls" && "active"
                  }`}
                  onClick={() => handleTabChange("live-calls")}
                >
                  Live Calls
                </button>
              </li>
            </ul>
            <div className="tab-content pt-2" id="borderedTabJustifiedContent">
              <div
                className={`tab-pane fade ${
                  activeTab === "live-calls" && "show active"
                }`}
                id="bordered-justified-campaign"
                role="tabpanel"
                aria-labelledby="campaign-tab"
              >
                {isLoading && (
                  <div className="d-flex justify-content-center my-5">
                    <ReactLoading
                      type="spokes"
                      color="grey"
                      height={50}
                      width={50}
                    />
                  </div>
                )}

                {!isLoading && (
                  <div className="datatable">
                    <DataTable
                      columns={Live_calls_data}
                      data={filteredData}
                      noHeader
                      defaultSortField="id"
                      // sortIcon={<SortIcon />}
                      defaultSortAsc={true}
                      pagination
                      highlightOnHover
                      // dense
                      overflowY
                      customStyles={tableCustomStyles}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default LiveSummary;
